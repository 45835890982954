<template>
    <div :is="layout">
        <div id="cesiumContainer"></div>
    </div>
</template>
<script>
    export default {
        name: 'example',
        data: () => ({
            layout: null
        }),
        mounted() {
            this.layout = this.$route.meta.use_layout
            this.$nextTick(() => {
                const Cesium = this.Cesium
                const viewer = new Cesium.Viewer('cesiumContainer')

                const polygon = new Cesium.PolygonGeometry({
                    polygonHierarchy: new Cesium.PolygonHierarchy(
                        Cesium.Cartesian3.fromDegreesArray([
                            90, 40,
                            90, 41,
                            93, 41,
                            93, 42,
                            93, 50
                        ])
                    ),
                    height: 1000000,
                    extrudedHeight: 0,
                })
                const geometry = new Cesium.PolygonGeometry.createGeometry(polygon)
                const polygonInstance = new Cesium.GeometryInstance({
                    geometry: geometry,
                    attributes: {
                        color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.RED)
                    },
                    id: 'hello'
                })

                const primitive = new Cesium.Primitive({
                    geometryInstances: [polygonInstance],
                    asynchronous: false,
                    appearance: new Cesium.PerInstanceColorAppearance({
                        flat: true,
                        translucent: false,
                    })
                })

                viewer.scene.primitives.add(primitive)
            })
        }
    }
</script>
